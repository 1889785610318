// import appConfig from '@/utils/appConfig'

export const formatEssentialManifest = (manifest) => {
  const esManifest = JSON.parse(manifest) || []
  const resData = esManifest.map(coin => ({
    ...coin,
    label: coin.key.toUpperCase(),
    baseUrl: coin.baseUrl,
    // addressRegex: ".*",
    // txRegex: ".*",
    family: 'eth',
    isNeedTokensCall: false,
    essential: true,
  }))

  if (!resData.length) return []
  return resData
}
