import { AbilityBuilder, Ability } from '@casl/ability'
import analyticsState from "@/store/modules/analytics/state"

export default function defineAbilityFor(coin = 'btc') {
    const { can, rules } = new AbilityBuilder(Ability);

    // defined permissions
    if (coin === 'btc' || coin === 'ltc' || coin === 'bsv') {
        can(['use'], 'btc')
    }

    if (coin === 'eth' || coin === 'bnb' || coin === 'trx' || coin === 'avax' || analyticsState.essentialCurrencyList?.some(c => c.key == coin)) {
        can(['use'], 'eth')
    }

    return new Ability(rules);
}
